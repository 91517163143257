export default [
    { colorName: 'Red', colorHex: '#c51111' },
    { colorName: 'Blue', colorHex: '#132fd2' },
    { colorName: 'Green', colorHex: '#127f2d' },
    { colorName: 'Pink', colorHex: '#ed54ba' },
    { colorName: 'Orange', colorHex: '#f17d0e' },
    { colorName: 'Yellow', colorHex: '#f3f457' },
    { colorName: 'Black', colorHex: '#3f484e' },
    { colorName: 'White', colorHex: '#d6e0f0' },
    { colorName: 'Purple', colorHex: '#6a2fb9' },
    { colorName: 'Brown', colorHex: '#72491e' },
    { colorName: 'Cyan', colorHex: '#38fedc' },
    { colorName: 'Lime', colorHex: '#51ef39' },
    { colorName: 'Maroon', colorHex: '#6c2b3d' },
    { colorName: 'Rose', colorHex: '#ecc0d3' },
    { colorName: 'Banana', colorHex: '#fffdbe' },
    { colorName: 'Gray', colorHex: '#708497' },
    { colorName: 'Tan', colorHex: '#928776' },
    { colorName: 'Coral', colorHex: '#ec7578' },
];
